import { Button, Icon, LegacyStack, Spinner, Text, TextField } from "@shopify/polaris";
import { ArrowDownMinor, ArrowUpMinor } from "@shopify/polaris-icons";
import { ICustomerPortalThemeForm, SECTION } from "@smartrr/shared/entities/CustomerPortalTheme";
import { IOrgInstaUserData } from "@smartrr/shared/entities/Organization";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { InstagramIcon } from "@vendor-app/app/_sharedComponents/Icons/Social Icons/InstagramIcon";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";

import { Accordion } from "../../components/Accordion";
import EmbeddedAccordion from "../../components/EmbeddedAccordion";
import {
  AccordionItemWrapper,
  EmbeddedWrapper,
  Eye,
  IconWrapper,
  SubItemWrapper,
  ThemingColorPicker,
  ThemingRangeSlider,
} from "../styles";
import { addInstagramUser, disconnectInstagramUser, instaAuthHandler } from "../utils";
import { ThemingStoreAccess } from "../../store";

interface ModernPortalSettingsProps {
  themeValues: ICustomerPortalThemeForm;
  sectionsOrder: SECTION[];
}

const ModernPortalSettings = ({ themeValues, sectionsOrder }: ModernPortalSettingsProps) => {
  const { updateCurrentThemeValues: setThemeFieldValue } = ThemingStoreAccess.useActions();
  const activeOrg = useActiveOrganizationSelector();
  const [instagramUser, setInstagramUser] = useState<IOrgInstaUserData | null>(
    activeOrg?.instagramUserData ?? null
  );
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const flags = useSmartrrFlags();
  const showSubscriptionManagementSectionV2 = useMemo(() => flags.subscriptionManagementSectionV2, [flags]);

  const orderHistoryIndex = useMemo(() => {
    return sectionsOrder.indexOf(SECTION.ORDERHISTORY);
  }, [sectionsOrder]);
  const subscriptionIndex = useMemo(() => {
    return sectionsOrder.indexOf(SECTION.SUBSCRIPTION);
  }, [sectionsOrder]);
  const rewardsIndex = useMemo(() => {
    return sectionsOrder.indexOf(SECTION.LOYALTY);
  }, [sectionsOrder]);
  const settingsIndex = useMemo(() => {
    return sectionsOrder.indexOf(SECTION.SETTINGS);
  }, [sectionsOrder]);
  const instagramIndex = useMemo(() => {
    return sectionsOrder.indexOf(SECTION.INSTAGRAM);
  }, [sectionsOrder]);
  const trendingIndex = useMemo(() => {
    return sectionsOrder.indexOf(SECTION.TRENDING);
  }, [sectionsOrder]);

  const reorderArray = (event: { oldIndex: number; newIndex: number }, originalArray: string[]) => {
    const movedItem = originalArray.find((item, index) => index === event.oldIndex);
    const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

    return [...remainingItems.slice(0, event.newIndex), movedItem, ...remainingItems.slice(event.newIndex)];
  };

  const changeOrder = useCallback(
    (index: number, direction: string) => {
      const lastElIndex = sectionsOrder.indexOf(sectionsOrder[sectionsOrder.length - 1]);
      if ((index === 0 && direction === "UP") || (index === lastElIndex && direction === "DOWN")) {
        return;
      }
      const val = reorderArray(
        { oldIndex: index, newIndex: index + (direction === "UP" ? -1 : 1) },
        sectionsOrder!
      );
      setThemeFieldValue("modernThemeSettings.sectionsOrder", val);
    },
    [sectionsOrder]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get("code");
    async function authInstaCode() {
      if (codeParam && isEmpty(instagramUser)) {
        setLoadingUser(true);
        const data = await addInstagramUser(codeParam);
        setInstagramUser(data);
        setLoadingUser(false);
      }
    }
    authInstaCode();
  }, [instagramUser]);

  return (
    <div data-testid="sidebar-theme-section">
      <React.Fragment>
        <React.Fragment>
          <Accordion title="sections">
            <EmbeddedWrapper>
              <EmbeddedAccordion
                themeValue={themeValues.modernThemeSettings.sections.banner.display}
                themeValueName={"modernThemeSettings.sections.banner.display"}
                title="Banner"
              >
                <div>
                  <p style={{ marginBottom: "5px" }}>Banner text</p>
                  <TextField
                    label=""
                    value={themeValues.modernThemeSettings.sections.banner.text}
                    onChange={val => setThemeFieldValue("modernThemeSettings.sections.banner.text", val)}
                    autoComplete="off"
                  />
                  <div style={{ marginTop: "10px" }}>
                    <ThemingColorPicker
                      title={"Background color"}
                      valueName={"modernBannerColor"}
                      value={themeValues.modernBannerColor?.toUpperCase() ?? "#F4F4F4"}
                    />
                  </div>
                </div>
              </EmbeddedAccordion>
            </EmbeddedWrapper>

            {[
              <EmbeddedWrapper key={subscriptionIndex} data-testid="sidebar-theme-section-subscriptions">
                <EmbeddedAccordion
                  themeValue={themeValues.modernThemeSettings.sections.subscriptions.display}
                  themeValueName={"modernThemeSettings.sections.subscriptions.display"}
                  title="Subscriptions"
                  changeOrder={changeOrder}
                  themeValues={themeValues}
                  index={subscriptionIndex}
                >
                  <SubItemWrapper style={{ marginBottom: "10px" }}>
                    <span>Add-ons</span>
                    <div style={{ marginRight: "-3px" }}>
                      <Eye
                        value={themeValues.modernThemeSettings.sections.subscriptions.addOns}
                        valueName="modernThemeSettings.sections.subscriptions.addOns"
                      />
                    </div>
                  </SubItemWrapper>
                  {showSubscriptionManagementSectionV2 ? (
                    <SubItemWrapper>
                      <span>Subscription ALT #1</span>
                      <div style={{ marginRight: "-3px" }}>
                        <Eye
                          value={themeValues.modernThemeSettings.sections.subscriptions.altView1}
                          valueName="modernThemeSettings.sections.subscriptions.altView1"
                        />
                      </div>
                    </SubItemWrapper>
                  ) : (
                    <React.Fragment />
                  )}
                </EmbeddedAccordion>
              </EmbeddedWrapper>,
              <AccordionItemWrapper key={orderHistoryIndex} data-testid="sidebar-theme-section-order-history">
                <div style={{ alignItems: "center" }}>Order History</div>

                <IconWrapper>
                  <Eye
                    value={themeValues.modernThemeSettings.sections.orderHistory.display}
                    valueName="modernThemeSettings.sections.orderHistory.display"
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      changeOrder(orderHistoryIndex, "DOWN");
                    }}
                  >
                    <Icon
                      source={ArrowDownMinor}
                      color={orderHistoryIndex === sectionsOrder.length - 1 ? "subdued" : "base"}
                    />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      changeOrder(orderHistoryIndex, "UP");
                    }}
                  >
                    <Icon source={ArrowUpMinor} color={orderHistoryIndex === 0 ? "subdued" : "base"} />
                  </div>
                </IconWrapper>
              </AccordionItemWrapper>,

              <AccordionItemWrapper key={settingsIndex} data-testid="sidebar-theme-section-settings">
                Settings
                <IconWrapper>
                  <Eye
                    value={themeValues.modernThemeSettings.sections.settings.display}
                    valueName="modernThemeSettings.sections.settings.display"
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      changeOrder(settingsIndex, "DOWN");
                    }}
                  >
                    <Icon
                      source={ArrowDownMinor}
                      color={settingsIndex === sectionsOrder.length - 1 ? "subdued" : "base"}
                    />
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      changeOrder(settingsIndex, "UP");
                    }}
                  >
                    <Icon source={ArrowUpMinor} color={settingsIndex === 0 ? "subdued" : "base"} />
                  </div>
                </IconWrapper>
              </AccordionItemWrapper>,
              ...(sectionsOrder.includes(SECTION.TRENDING) && themeValues.modernThemeSettings.sections.trending
                ? [
                    <AccordionItemWrapper key={trendingIndex} data-testid="sidebar-theme-section-trending">
                      Trending
                      <IconWrapper>
                        <Eye
                          value={themeValues.modernThemeSettings.sections.trending.display}
                          valueName="modernThemeSettings.sections.trending.display"
                        />
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            changeOrder(trendingIndex, "DOWN");
                          }}
                        >
                          <Icon
                            source={ArrowDownMinor}
                            color={trendingIndex === sectionsOrder.length - 1 ? "subdued" : "base"}
                          />
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            changeOrder(trendingIndex, "UP");
                          }}
                        >
                          <Icon source={ArrowUpMinor} color={trendingIndex === 0 ? "subdued" : "base"} />
                        </div>
                      </IconWrapper>
                    </AccordionItemWrapper>,
                  ]
                : []),
              ...(sectionsOrder.includes(SECTION.INSTAGRAM) && themeValues.modernThemeSettings.sections.instagram
                ? [
                    <EmbeddedWrapper key={instagramIndex} data-testid="sidebar-theme-section-instagram">
                      <EmbeddedAccordion
                        themeValue={themeValues.modernThemeSettings.sections.instagram.display}
                        themeValueName={"modernThemeSettings.sections.instagram.display"}
                        title="Instagram"
                        changeOrder={changeOrder}
                        themeValues={themeValues}
                        index={instagramIndex}
                      >
                        <SubItemWrapper>
                          <LegacyStack>
                            <InstagramIcon />
                            <LegacyStack vertical>
                              {loadingUser ? (
                                <Spinner />
                              ) : (
                                <React.Fragment>
                                  {isEmpty(instagramUser) ? (
                                    <React.Fragment>
                                      <Button primary onClick={() => instaAuthHandler()}>
                                        Log in with Instagram
                                      </Button>
                                      <div style={{ marginTop: "5px", color: "var(--p-color-text-subdued)" }}>
                                        <Text variant="bodySm" as="p">
                                          No account connected
                                        </Text>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Button onClick={() => disconnectInstagramUser()}>Disconnect</Button>
                                      <div style={{ marginTop: "5px" }}>
                                        <Text variant="bodySm" as="p">
                                          Logged in as{" "}
                                          <span style={{ fontWeight: "bold" }}>
                                            &#64;{instagramUser?.username!}
                                          </span>
                                        </Text>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </LegacyStack>
                          </LegacyStack>
                        </SubItemWrapper>
                      </EmbeddedAccordion>
                    </EmbeddedWrapper>,
                  ]
                : []),
              ...(sectionsOrder.includes(SECTION.LOYALTY) && themeValues.modernThemeSettings.sections.rewards
                ? [
                    <AccordionItemWrapper key={rewardsIndex} data-testid="sidebar-theme-section-loyalty">
                      Loyalty
                      <IconWrapper>
                        <Eye
                          value={themeValues.modernThemeSettings.sections.rewards.display}
                          valueName="modernThemeSettings.sections.rewards.display"
                        />
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            changeOrder(rewardsIndex, "DOWN");
                          }}
                        >
                          <Icon
                            source={ArrowDownMinor}
                            color={rewardsIndex === sectionsOrder.length - 1 ? "subdued" : "base"}
                          />
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            changeOrder(rewardsIndex, "UP");
                          }}
                        >
                          <Icon source={ArrowUpMinor} color={rewardsIndex === 0 ? "subdued" : "base"} />
                        </div>
                      </IconWrapper>
                    </AccordionItemWrapper>,
                  ]
                : []),
            ]
              .map(s => {
                return s;
              })
              .sort((a, b) => Number(a?.key) - (b! && Number(b?.key)))}
          </Accordion>

          <Accordion title="main colors">
            <div style={{ marginTop: "1rem" }}>
              <ThemingColorPicker
                title={"Page background"}
                valueName={"modernPageBackground"}
                value={themeValues.modernPageBackground?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Card background"}
                valueName={"modernCardBackground"}
                value={themeValues.modernCardBackground?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Menu background"}
                valueName={"modernMenuBackground"}
                value={themeValues.modernMenuBackground?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Badge background"}
                valueName={"modernBadgeBackground"}
                value={themeValues.modernBadgeBackground?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Line dividers"}
                valueName={"modernLineDividers"}
                value={themeValues.modernLineDividers?.toUpperCase()}
              />
            </div>
          </Accordion>

          <Accordion title="borders">
            <ThemingRangeSlider
              value={themeValues.modernCardRadius}
              title="Card corner radius"
              valueName={"modernCardRadius"}
              min={0}
              max={60}
            />

            <ThemingRangeSlider
              value={themeValues.modernCardBorder}
              title="Card border"
              valueName={"modernCardBorder"}
              min={0}
              max={5}
            />

            <ThemingColorPicker
              title={"Card border color"}
              valueName={"modernCardBorderColor"}
              value={themeValues.modernCardBorderColor?.toUpperCase()}
            />

            <ThemingRangeSlider
              value={themeValues.modernSmallCardBorderRadius}
              title="Mini Card Corner Radius"
              valueName={"modernSmallCardBorderRadius"}
              min={0}
              max={5}
            />

            <ThemingRangeSlider
              value={themeValues.modernSmallCardBorderWidth}
              title="Mini Card border"
              valueName={"modernSmallCardBorderWidth"}
              min={0}
              max={5}
            />

            <ThemingColorPicker
              title={"Mini card border color"}
              valueName={"modernSmallCardBorderColor"}
              value={themeValues.modernSmallCardBorderColor?.toUpperCase()}
            />
          </Accordion>

          <Accordion title="buttons">
            <div style={{ marginTop: "1rem" }}>
              <ThemingColorPicker
                title={"Primary button color"}
                valueName={"modernPrimaryButtonColor"}
                value={themeValues.modernPrimaryButtonColor?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Primary button border color"}
                valueName={"modernPrimaryButtonBorderColor"}
                value={themeValues.modernPrimaryButtonBorderColor?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Secondary button color"}
                valueName={"modernSecondaryButtonColor"}
                value={themeValues.modernSecondaryButtonColor?.toUpperCase()}
              />

              <ThemingColorPicker
                title={"Secondary button border color"}
                valueName={"modernSecondaryButtonBorderColor"}
                value={themeValues.modernSecondaryButtonBorderColor?.toUpperCase()}
              />

              <ThemingRangeSlider
                value={themeValues.modernButtonBorderWidth}
                title="Button border width"
                valueName={"modernButtonBorderWidth"}
                min={0}
                max={5}
              />
              <ThemingRangeSlider
                value={themeValues.modernButtonBorderRadius}
                title="Button border radius"
                valueName={"modernButtonBorderRadius"}
                min={0}
                max={60}
              />
              <ThemingRangeSlider
                value={themeValues.modernButtonBorderHeight}
                title="Button height"
                valueName={"modernButtonBorderHeight"}
                min={0}
                max={60}
              />
            </div>
          </Accordion>
        </React.Fragment>

        <Accordion title="product images">
          <ThemingRangeSlider
            value={themeValues.modernImageBorderRadius}
            title="Image corner radius"
            valueName={"modernImageBorderRadius"}
            min={0}
            max={60}
          />

          <ThemingRangeSlider
            value={themeValues.modernSmallImageBorderRadius}
            title="Small image corner radius"
            valueName={"modernSmallImageBorderRadius"}
            min={0}
            max={60}
          />
        </Accordion>
      </React.Fragment>
    </div>
  );
};

export default ModernPortalSettings;
