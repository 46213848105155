import { Spinner } from "@shopify/polaris";
import { ICustomerPortalThemeForm } from "@smartrr/shared/entities/CustomerPortalTheme";
import React from "react";

import ModernPortalSettings from "./ModernPortalSettings";

interface Props {
  themeValues: ICustomerPortalThemeForm;
}

const ThemePageSidebar = ({ themeValues }: Props) => {
  const sectionsOrder = themeValues.modernThemeSettings.sectionsOrder;

  if (!sectionsOrder) {
    return <Spinner />;
  }
  return <ModernPortalSettings themeValues={themeValues} sectionsOrder={sectionsOrder} />;
};

export default ThemePageSidebar;
