import { PasswordlessStrategy } from "@smartrr/shared/entities/CustomerPortalTheme";

export const customerLoginSelections = [
  {
    label: "Traditional login",
    disabled: false,
    value: false,
  },
  {
    label: "Passwordless login",
    disabled: false,
    value: true,
  },
];

export const passwordlessStrategy: {
  label: string;
  value: PasswordlessStrategy;
}[] = [
  {
    label: "One Time Code",
    value: "otp",
  },
  {
    label: "Magic Link",
    value: "magic-link",
  },
];

export const passwordlessChoices = [
  {
    value: "traditional",
    label: "Traditional",
  },
  {
    value: "passwordless",
    label: "Passwordless",
  },
];
