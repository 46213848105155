import { ICustomerPortalThemeForm } from "@smartrr/shared/entities/CustomerPortalTheme";
import React from "react";

import AdvancedSettings from "./AdvancedSettings";

interface IProductSidebar {
  themeValues: ICustomerPortalThemeForm;
  setThemeFieldValue: any;
}

const ProductSidebar = ({ themeValues, setThemeFieldValue }: IProductSidebar) => {
  return <AdvancedSettings themeValues={themeValues} setThemeFieldValue={setThemeFieldValue} />;
};

export default ProductSidebar;
