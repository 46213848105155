import React, { useCallback, useMemo, useState } from "react";
import { ChoiceList, Icon, LegacyStack, Link, Popover, Select, Text } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import { ThemingStoreAccess } from "@vendor-app/app/AdminRoute/AdminThemingRoute/store";
import { ICustomerPortalThemeForm } from "@smartrr/shared/entities/CustomerPortalTheme";
import { passwordlessChoices } from "../libs/constants";

interface PasswordlessSettingsProps {
  themeValues: ICustomerPortalThemeForm;
}

export const PasswordlessSettings = ({ themeValues }: PasswordlessSettingsProps) => {
  const isPasswordless = themeValues.isPasswordless;
  const passwordlessStrategy = themeValues.passwordlessStrategy;
  const { updateCurrentThemeValues: setThemeFieldValue } = ThemingStoreAccess.useActions();
  const initialSelected = useMemo(() => (isPasswordless ? "passwordless" : "traditional"), [isPasswordless]);
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive(isActive => !isActive), []);

  const PopoverContent = () => (
    <Text as="span" variant="bodyMd">
      <Text as="span" variant="bodyMd" fontWeight="bold">
        Magic links
      </Text>{" "}
      allow the customer to log in with a link sent to their email address.{" "}
      <Text as="span" variant="bodyMd" fontWeight="bold">
        One-time code
      </Text>{" "}
      prompts the customer to paste a six-digit generated code sent to their email address.{" "}
      <Link url="https://help.smartrr.com/docs/support/admin-portal/how-do-i-set-up-passwordless-login" external>
        Learn more
      </Link>
      .
    </Text>
  );

  const activator = (
    <div onMouseEnter={() => setPopoverActive(true)}>
      <Icon source={CircleInformationMajor} />
    </div>
  );

  return (
    <React.Fragment>
      <LegacyStack vertical>
        <ChoiceList
          title="Customer login"
          titleHidden={true}
          choices={passwordlessChoices}
          selected={[initialSelected]}
          onChange={selected => {
            const choice = !!selected.includes("passwordless");
            setThemeFieldValue("isPasswordless", choice);
          }}
        />
        {isPasswordless ? (
          <LegacyStack spacing="tight" distribution="fill">
            <LegacyStack spacing="tight">
              <Text as="span" variant="bodyMd">
                Passwordless Type
              </Text>
              <Popover
                sectioned
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
                preferredAlignment="right"
                preferredPosition="above"
                ariaHaspopup={false}
              >
                <div onMouseEnter={() => setPopoverActive(true)} onMouseLeave={() => setPopoverActive(false)}>
                  <PopoverContent />
                </div>
              </Popover>
            </LegacyStack>
            <Select
              label=""
              options={[
                { label: "Magic link", value: "magic-link" },
                { label: "One time code", value: "otp" },
              ]}
              onChange={v => setThemeFieldValue("passwordlessStrategy", v)}
              value={passwordlessStrategy}
            />
          </LegacyStack>
        ) : (
          <React.Fragment />
        )}
      </LegacyStack>
    </React.Fragment>
  );
};
