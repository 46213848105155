import { Box, Stack, Typography, styled, useTheme } from "@mui/material";
import { prefixClassName } from "@smartrr/shared/components/primitives";
import { AquariusButton } from "@smartrr/shared/customerPortal/components/AquariusButton";
import { AquariusCard } from "@smartrr/shared/customerPortal/components/AquariusCard";
import { typedLocalize } from "@smartrr/shared/utils/sharedTranslations/localize";
import { InstagramFill } from "akar-icons";
import { truncate } from "lodash";
import { rgba } from "polished";
import React, { useEffect } from "react";
import { ThemingStoreAccess } from "../../store";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";

import { PaymentCardTitle, SectionTitle } from "./Settings";
import { getVendorInstagramData } from "../../sections/utils";

interface CardProps {
  id: string;
  caption: string;
  timestamp: string;
  media_url: string;
  permalink: string;
}

const MAX_CHARACTERS = 80;

const StyledCard = styled(AquariusCard)`
  box-shadow:
    0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08) !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 315px;
  justify-content: center;
  padding: 12px !important;
  transition: 0.3s ease-in-out;
  width: 254px;

  &:hover {
    background-color: ${props => rgba(props.theme.modernPageBackground!, 0.7)};
  }

  & img {
    max-height: 230px;
    max-width: 230px;
    object-fit: cover;
    border-radius: 6px;
  }
  & p {
    width: inherit;
    &:last-child {
      margin-top: 3px;
      opacity: 80%;
    }
  }
`;

const SingleCard = ({ media_url, timestamp, caption, permalink, ...props }: CardProps) => {
  return (
    <StyledCard className={prefixClassName("social-post-card")} onClick={() => window.open(permalink)}>
      <img src={media_url} />
      <Box
        mt={"10px"}
        sx={{
          "& p": {
            fontSize: "12px",
            color: "#202223",
            ":last-child": {
              opacity: "80%",
            },
          },
        }}
      >
        <p>{truncate(caption, { length: MAX_CHARACTERS })}</p>
        <p>
          {typedLocalize("utils.dates.toViewDateLong", {
            date: new Date(timestamp),
          })}
        </p>
      </Box>
    </StyledCard>
  );
};

export const Instagram = React.memo((): JSX.Element => {
  const postData = ThemingStoreAccess.usePostdata();
  const { setPostData } = ThemingStoreAccess.useActions();
  const { addToast } = useToast();
  const userData = useActiveOrganizationSelector()?.instagramUserData;

  const fetchInstagramData = async () => {
    const res = await getVendorInstagramData();
    if (res.type === "error") {
      addToast("Error fetching instagram data");
    } else {
      setPostData(res.body);
    }
  };
  useEffect(() => {
    userData?.username && !postData && fetchInstagramData();
  }, [userData]);

  const theme = useTheme();
  return (
    <React.Fragment>
      {postData ? (
        <Box className={prefixClassName("social-section-wrapper")} sx={{ marginBottom: "10px" }}>
          <SectionTitle>{typedLocalize("social.title")}</SectionTitle>
          <AquariusCard sx={{ padding: "20px 0 0 0 !important" }}>
            <Stack
              className={prefixClassName("social-header")}
              sx={{
                flexDirection: "row",
                padding: "0 20px 0 20px",
                marginBottom: "15px",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <Stack direction="column" spacing="3px">
                <PaymentCardTitle style={{ margin: "0px" }}>@{userData?.username}</PaymentCardTitle>
                <Typography sx={{ opacity: "80%", fontSize: "12px", color: "#202223" }}>
                  {typedLocalize("social.postCount", { mediaCount: userData?.mediaCount })}
                </Typography>
              </Stack>
              <AquariusButton
                href={`https://www.instagram.com/${userData?.username}`}
                sx={{ width: "max-content", margin: "0px" }}
              >
                <InstagramFill size="15" color={theme.modernIconsColor} style={{ marginRight: "5px" }} />
                {typedLocalize("social.followBtn")}
              </AquariusButton>
            </Stack>
            <Box
              sx={{
                overflowX: "scroll",
                width: "100%",
                overflowY: "hidden",
              }}
            >
              <Stack
                direction="row"
                spacing={"10px"}
                className={prefixClassName("social-posts-wrapper")}
                sx={{
                  width: "max-content",
                  padding: "0 20px 20px 20px",
                }}
              >
                {postData.data.map(post => (
                  <SingleCard key={post.id} {...post} />
                ))}
              </Stack>
            </Box>
          </AquariusCard>
        </Box>
      ) : null}
    </React.Fragment>
  );
});
