import { css } from "@codemirror/lang-css";
import MUIBox from "@mui/material/Box";
import MUIModal from "@mui/material/Modal";
import { Button, ContextualSaveBar, Icon, LegacyCard, LegacyStack, Link, Tabs, Text } from "@shopify/polaris";
import {
  CircleInformationMajor,
  DesktopMajor,
  ExternalSmallMinor,
  HintMajor,
  MobileMajor,
} from "@shopify/polaris-icons";
import { ICustomerPortalTheme, ICustomerPortalThemeForm } from "@smartrr/shared/entities/CustomerPortalTheme";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { trimObjectStringProperties } from "@smartrr/shared/utils/trimObjectStringProperties";
import React, { useCallback } from "react";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  ButtonWrapper,
  Content,
  IconTab,
  IconWrapper,
  LeftTab,
  LinkWrapper,
  ModernOverrideCard,
  ModernThemeContainer,
  PreviewText,
  RightTab,
  SideBar,
  Wrapper,
} from "./styles";
import CodeMirror from "@uiw/react-codemirror";
import { ApplyToThemeModal } from "../../components/ApplyToThemeModal";
import ThemePageSidebar from "../SideBar/GlobalAndPortalTab";
import ProductSidebar from "../SideBar/ProductAndAdvancedTab";
import { ThemingStoreAccess } from "../../store";
import {
  codeMirrorContainerStyle,
  DesktopPreview,
  MobilePreview,
  TabOptions,
  ThemeSettingsTabs,
} from "./libs/constants";
import { AquariusContent } from "./components/AquariusContent";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mdsm: true; // adds the `mobile` breakpoint
  }

  interface Theme extends ICustomerPortalTheme {
    breakPoints?: BreakpointOverrides;
  }

  interface TypographyVariants {
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    //Not supporting default `caption` variant, replacing it with caption1 & caption2 for more flexibility
    caption1: true;
    caption2: true;
    // Unsupported ---
    subtitle1: false;
    subtitle2: false;
    caption: false;
    overline: false;
  }
}

export interface IGlobalThemesProps {
  themes: ISmartrrShopTheme[];
  currentThemeValues: ICustomerPortalThemeForm;
}

export function Theming({ themes, currentThemeValues }: IGlobalThemesProps): JSX.Element {
  const { addToast } = useToast();
  const customerPortalThemeIsLoading = ThemingStoreAccess.useIsLoading();
  const {
    get: getCustomerPortalTheme,
    discard,
    setThemeState,
    update: updateCustomerPortalTheme,
    updateCurrentThemeValues: setThemeFieldValue,
  } = ThemingStoreAccess.useActions();

  const { selectedPreview, selectedTab, showThemesModal, isModalOpen, disableDiscard, hasThemeChanges } =
    ThemingStoreAccess.useThemeProperties();

  const handleOpen = () => setThemeState("isModalOpen", true);
  const handleClose = () => setThemeState("isModalOpen", false);

  const onSave = async () => {
    setThemeState("showThemesModal", true);
    setThemeState("disableDiscard", false);
  };
  const onClose = useCallback(() => setThemeState("showThemesModal", false), []);

  const onApplyCSSToTheme = async (theme: ISmartrrShopTheme) => {
    const trimmedValues = trimObjectStringProperties(currentThemeValues);
    await updateCustomerPortalTheme(theme.id, {
      ...trimmedValues,
      modernOverrideCSS: currentThemeValues.modernOverrideCSS,
    });

    setThemeState("showThemesModal", false);
    addToast("Theme updated");
    await getCustomerPortalTheme();
  };

  const onThemingDiscard = () => discard();

  return (
    <Wrapper>
      <SideBar>
        <Tabs
          fitted
          tabs={TabOptions}
          selected={selectedTab === ThemeSettingsTabs.GLOBAL ? 0 : 1}
          onSelect={index =>
            setThemeState("selectedTab", index === 0 ? ThemeSettingsTabs.GLOBAL : ThemeSettingsTabs.PRODUCT)
          }
        >
          {selectedTab === ThemeSettingsTabs.GLOBAL ? (
            <ThemePageSidebar themeValues={currentThemeValues} />
          ) : (
            <ProductSidebar themeValues={currentThemeValues} setThemeFieldValue={setThemeFieldValue} />
          )}
        </Tabs>
      </SideBar>
      <Content>
        {!!hasThemeChanges && (
          <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
              onAction: onSave,
              loading: customerPortalThemeIsLoading,
              content: "Save changes",
              disabled: false,
            }}
            discardAction={{
              content: "Discard changes",
              onAction: onThemingDiscard,
              disabled: disableDiscard,
            }}
          />
        )}
        <ApplyToThemeModal
          open={showThemesModal}
          themes={themes}
          onClose={onClose}
          onSubmit={onApplyCSSToTheme}
          lastSavedThemeId={currentThemeValues.lastSavedThemeId}
        />
        <React.Fragment>
          <div style={{ display: " flex", justifyContent: "center" }}>
            <IconTab style={{ marginRight: "10px" }}>
              <LeftTab
                preview={selectedPreview === "mobile"}
                onClick={() => {
                  setThemeState("selectedPreview", "mobile");
                  handleOpen();
                }}
              >
                <Icon source={MobileMajor} color="base" />
              </LeftTab>
              <RightTab
                preview={selectedPreview === "desktop"}
                onClick={() => {
                  setThemeState("selectedPreview", "desktop");
                  handleOpen();
                }}
              >
                <Icon source={DesktopMajor} color="base" />
              </RightTab>
            </IconTab>
            <PreviewText>{` You're previewing how your account portal will look to customers`}</PreviewText>
          </div>

          <ModernThemeContainer>
            <div style={{ position: "relative" }}>
              <MUIBox
                sx={{
                  height: 960,
                  width: 375,
                  marginRight: "1.5rem",
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "scroll",
                }}
              >
                <AquariusContent themeValues={currentThemeValues} selectedPreview={selectedPreview} />
                <div>
                  <MUIModal
                    open={isModalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                      style: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
                    }}
                  >
                    <React.Fragment>
                      <div className="Polaris-Frame__ContextualSaveBar Polaris-Frame-CSSAnimation--startFade Polaris-Frame-CSSAnimation--endFade">
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <div p-color-scheme="dark">
                          <div
                            className="Polaris-Frame-ContextualSaveBar"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0 32px",
                            }}
                          >
                            <IconTab>
                              <LeftTab
                                preview={selectedPreview === "mobile"}
                                onClick={() => setThemeState("selectedPreview", "mobile")}
                              >
                                <Icon source={MobileMajor} color="base" />
                              </LeftTab>
                              <RightTab
                                preview={selectedPreview === "desktop"}
                                onClick={() => setThemeState("selectedPreview", "desktop")}
                              >
                                <Icon source={DesktopMajor} color="base" />
                              </RightTab>
                            </IconTab>
                            <ButtonWrapper>
                              <Button
                                primary
                                onClick={() => {
                                  setThemeState("selectedPreview", "mobile");
                                  handleClose();
                                }}
                              >
                                Exit preview
                              </Button>
                            </ButtonWrapper>
                          </div>
                        </div>
                      </div>
                      {selectedPreview === "desktop" ? (
                        <DesktopPreview>
                          <AquariusContent themeValues={currentThemeValues} selectedPreview={selectedPreview} />
                        </DesktopPreview>
                      ) : (
                        <MobilePreview>
                          <AquariusContent themeValues={currentThemeValues} selectedPreview={selectedPreview} />
                        </MobilePreview>
                      )}
                    </React.Fragment>
                  </MUIModal>
                </div>
              </MUIBox>
            </div>
            <div>
              <ModernOverrideCard>
                <LegacyCard
                  sectioned
                  title={
                    <LegacyStack>
                      <Icon source={HintMajor} color="base" />
                      <Text variant="headingMd" as="h2">
                        Override Tips
                      </Text>
                    </LegacyStack>
                  }
                >
                  <div>
                    <div>
                      Custom code placed below will override any global or product settings. Use CSS rules to
                      apply branded customization or to add custom font and typography styling.
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      CSS override rules will be scoped to the following selectors:
                    </div>
                    <br />
                    <div>#smartrr_account</div>
                    <div>#smartrr_wrapper</div>
                    <div>#smartrr_toast_container</div>
                    <div>#smartrr_inner</div>
                    <div>#smartrr_header</div>

                    <IconWrapper>
                      <Icon source={CircleInformationMajor} color="primary" />
                      View Our{" "}
                      <Link
                        external
                        url={`https://help.smartrr.com/docs/support/admin-portal/what-are-css-overrides`}
                      >
                        <LinkWrapper>
                          CSS Variables.{<Icon source={ExternalSmallMinor} color="interactive" />}
                        </LinkWrapper>
                      </Link>
                    </IconWrapper>
                  </div>
                </LegacyCard>
              </ModernOverrideCard>
              <LegacyCard sectioned title="CSS Overrides">
                <div style={codeMirrorContainerStyle}>
                  <CodeMirror
                    value={currentThemeValues.modernOverrideCSS}
                    onChange={v => setThemeFieldValue("modernOverrideCSS", v)}
                    extensions={[css()]}
                  />
                </div>
              </LegacyCard>
            </div>
          </ModernThemeContainer>
        </React.Fragment>
      </Content>
    </Wrapper>
  );
}
